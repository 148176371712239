import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import MyTeamService from "../services/my_team.service";

const initialState = {
  isLoading: false,
  chosen_player: [],
  playingElevenPlayerSum: "",
  getAllSquadPlayer: [],
};

export const getPlayer = createAsyncThunk(
  "myTeam/getPlayer",
  async (query, thunkAPI) => {
    try {
      const data = await MyTeamService.getPlayingEleven(query);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const myTeamSlice = createSlice({
  name: "myTeam",
  initialState,
  extraReducers: {
    [getPlayer.pending]: (state, action) => {
      state.isLoading = true;
    },

    [getPlayer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.playerData = action.payload.data;
      state.player_structure = action.payload.player_structure;
      state.foundMinPostZero = action.payload.foundMinPostZero;
      state.user_team_data = action.payload.user_team_data;
      state.chosen_player = action.payload.chosen_player;
      state.availability = action.payload.availability;
      state.getAllSquadPlayer = action.payload.getAllSquadPlayer;
      state.club_details = action.payload.club_details;
      state.lockout_time = action.payload.lockout_time;
      state.playingElevenPlayerSum = action.payload.playingElevenPlayerSum;
      state.club_players_fantasy_points =
        action.payload.club_players_fantasy_points;
    },
    [getPlayer.rejected]: (state, action) => {
      state.isLoading = true;
    },
  },
});

const { reducer } = myTeamSlice;
export default reducer;
