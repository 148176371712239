// userPremiumUtils.js

// Function to check if the user has paid for a specific club
// export const isUserPaid = () => {
//   // Retrieve userPremium, club_details, and user from local storage
//   const userPremium = JSON.parse(localStorage.getItem("userPremimium")) || [];
//   const user = JSON.parse(localStorage.getItem("user")) || {};

//   // Example usage of club_id and user_id from club_details and user
//   const user_id_to_check = user.id;

//   // Find the entry in userPremium array for the given club_id and user_id
//   const clubEntry = userPremium.find(
//     (entry) => entry.user_id === user_id_to_check
//   );
//   // Check if the entry exists and is_paid is true
//   return clubEntry && clubEntry.is_paid;
// };

export const isUserPaid = () => {
  // Retrieve userPremium and clubDetails from local storage
  const userPremium = JSON.parse(localStorage.getItem("userPremimium")) || [];
  const clubDetails = JSON.parse(localStorage.getItem("club_details")) || {};
  const user = JSON.parse(localStorage.getItem("user")) || {};

  // First, check if any entry in userPremium with matching user_id has sub_type === 2
  for (const entry of userPremium) {
    if (entry.user_id === user.id && entry.sub_type === 2) {
      return 1;
    }
  }

  for (const clubEntry of userPremium) {
    // Check if clubDetails's "id" matches userPremium's "club_id"
    if (clubEntry.user_id === user.id && clubEntry.club === clubDetails.id) {
      if (clubEntry.sub_type === 1) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  // If no relevant entry is found, return 0
  return 0;
};
