import { agent } from "../utils/agent";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const getRank = async (selectedRoundId = "") => {
  const response = await agent.get(API_URL + `rank?roundId=${selectedRoundId}`);
  return response.data;
};
const getGameRank = async (clubId = "", selectedRoundId = "") => {
  const response = await axios.get(
    API_URL + `game-rank?club_id=${clubId}&roundId=${selectedRoundId}`
  );
  return response.data;
};
const proRanking = async (selectedRoundId = "") => {
  const response = await agent.get(
    API_URL + `proRanking?roundId=${selectedRoundId}`
  );
  return response.data;
};
const getRankByGameweek = async (roundId) => {
  const response = await agent.get(API_URL + `round-rank?round=${roundId}`);
  return response.data;
};
const getRoundBreakDown = async (teamId) => {
  const response = await agent.get(API_URL + `round-break-down/${teamId}`);
  return response.data;
};
const rankChart = async (teamId) => {
  const response = await agent.get(API_URL + `rankChart/${teamId}`);
  return response.data;
};
const pointRankChart = async (teamId) => {
  const response = await agent.get(API_URL + `pointRankChart/${teamId}`);
  return response.data;
};
const topTenRank = async (teamId) => {
  const response = await agent.get(API_URL + `topTenRank`);
  return response.data;
};

const RankService = {
  getRank,
  proRanking,
  getRankByGameweek,
  getRoundBreakDown,
  getGameRank,
  rankChart,
  pointRankChart,
  topTenRank,
};

export default RankService;
