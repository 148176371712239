import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
const GameAboutModal = (props) => {
  return (
    <Modal
      show={props.showGameAboutModal}
      onHide={props.handleGameAboutModalClose}
      size="lg"
      id="playermoreinfo"
    >
      <Modal.Header>Welcome Message</Modal.Header>
      <div
        className="player-performance1"
        style={{ maxHeight: "500px", overflowY: "auto" }}
      >
        {ReactHtmlParser(props.aboutDescription)}
      </div>
      <Modal.Footer>
        <div className="poweredby">
          Powered By
          <img src="images/logo.png" alt="img" />
        </div>
        <div className="">
          <Link
            className="border-btn"
            onClick={props.handleGameAboutModalClose}
          >
            <i className="fas fa-times"></i>
          </Link>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GameAboutModal;
