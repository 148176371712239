import React, { createContext, useContext, useState, useEffect } from "react";
import CommonService from "../services/common.service";

// Create a context
const GradientContext = createContext();

// Create a provider component
const GradientProvider = ({ children }) => {
  const [gradient, setGradient] = useState(
    "linear-gradient(236.28deg, #CA1744 4.07%, #0B1449 75.27%)"
  );
  const [bannerStyle, setBannerStyle] = useState({
    isImage: true,
    imageUrl: "",
    backgroundColor: "",
  });
  const [sideMenuBG, setsideMenuBG] = useState({
    backgroundColor: "#0A0F2E",
  });

  // Fetch the gradient and banner style from the API and update them
  useEffect(() => {
    const clubDetails = JSON.parse(localStorage.getItem("club_details"));
    var clubId = 0;
    if (clubDetails) {
      clubId = clubDetails.club_id;
      fetchBackgroundColor(clubId);
      fetchBannerStyle(clubId);
    }
  }, []);

  const fetchBackgroundColor = (clubId) => {
    CommonService.getBackgroundColor(clubId).then((res) => {
      if (res.success) {
        setGradient(res.data);
        document.documentElement.style.setProperty(
          "--dynamic-gradient",
          res.data
        );
        if (res.result?.is_default_sidebar === 1) {
          document.documentElement.style.setProperty(
            "--side-menu-background-color",
            res.result?.sidebar_bg_color
          );
        }
      }
    });
  };

  const fetchBannerStyle = (clubId) => {
    CommonService.getBannerStyle(clubId).then((res) => {
      if (res.success) {
        const isImage = res.data.is_image == 1;
        const imageUrl = isImage
          ? `${process.env.REACT_APP_FRONT_URL}images/${res.data.image_url}`
          : "";

        const backgroundColor = !isImage ? res.data.header_bg_color : "";
        setBannerStyle({ isImage, imageUrl, backgroundColor });
        updateCSSVariables({ isImage, imageUrl, backgroundColor });
      }
    });
  };
  const updateCSSVariables = (data) => {
    if (data.isImage) {
      document.documentElement.style.setProperty(
        "--banner-background-image",
        `url(${data.imageUrl})`
      );
      document.documentElement.style.setProperty(
        "--banner-background-color",
        ""
      );
    } else {
      document.documentElement.style.setProperty(
        "--banner-background-image",
        ""
      );
      document.documentElement.style.setProperty(
        "--banner-background-color",
        data.backgroundColor
      );
    }
  };
  const updateGradient = (clubId) => {
    fetchBackgroundColor(clubId);
    fetchBannerStyle(clubId);
  };

  return (
    <GradientContext.Provider value={{ gradient, bannerStyle, updateGradient }}>
      {children}
    </GradientContext.Provider>
  );
};

// Custom hook to use the Gradient context
const useGradient = () => useContext(GradientContext);

export { GradientProvider, useGradient };
