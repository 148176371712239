import React, { useEffect } from "react";
import { isUserPaid } from "../../utils/userPremiumUtils";
const GoogleAdsense = () => {
  const userPaidForClub = isUserPaid();
  useEffect(() => {
    if (userPaidForClub !== 1) {
      window.onload = function () {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      };
    }
  }, [userPaidForClub]);
  return (
    <>
      {" "}
      {userPaidForClub !== 1 && (
        <div
          className="ad google-ads"
          style={{ position: "relative", textAlign: "center" }}
        >
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-2014979197722705"
            data-ad-slot="5268698739"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
      )}
    </>
  );
};

export default GoogleAdsense;
